// Generated by Framer (fcda68f)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["i16XUK4K3"];

const serializationHash = "framer-EKSf0"

const variantClassNames = {i16XUK4K3: "framer-v-1xqh9vc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "i16XUK4K3", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-EKSf0", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1xqh9vc", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"i16XUK4K3"} ref={ref ?? ref1} style={{backgroundColor: "rgb(0, 0, 0)", borderBottomLeftRadius: 212, borderBottomRightRadius: 212, borderTopLeftRadius: 212, borderTopRightRadius: 212, ...style}}><SVG className={"framer-tuwtf3"} data-framer-name={"Icon"} layout={"position"} layoutDependency={layoutDependency} layoutId={"TDLC0s2y8"} opacity={1} style={{backgroundColor: "rgb(0, 0, 0)"}} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 20 20\"><path d=\"M 10 1 C 14.971 1 19 5.029 19 10 C 19 14.971 14.971 19 10 19 C 5.029 19 1 14.971 1 10 C 1 5.029 5.029 1 10 1 Z M 6.464 12.121 C 6.074 12.512 6.074 13.145 6.464 13.536 C 6.855 13.926 7.488 13.926 7.879 13.536 L 10 11.414 L 12.121 13.536 C 12.512 13.926 13.145 13.926 13.536 13.536 C 13.926 13.145 13.926 12.512 13.536 12.121 L 11.414 10 L 13.536 7.879 C 13.926 7.488 13.926 6.855 13.536 6.464 C 13.145 6.074 12.512 6.074 12.121 6.464 L 10 8.586 L 7.879 6.464 C 7.488 6.074 6.855 6.074 6.464 6.464 C 6.074 6.855 6.074 7.488 6.464 7.879 L 8.586 10 Z\" fill=\"#ffffff\"></path></svg>"} svgContentId={3563410749} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-EKSf0 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-EKSf0 .framer-1hsy46h { display: block; }", ".framer-EKSf0 .framer-1xqh9vc { height: 20px; overflow: hidden; position: relative; width: 20px; will-change: var(--framer-will-change-override, transform); }", ".framer-EKSf0 .framer-tuwtf3 { flex: none; height: 20px; left: calc(50.00000000000002% - 20px / 2); position: absolute; top: calc(50.00000000000002% - 20px / 2); width: 20px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const FramerlUQcbzqmY: React.ComponentType<Props> = withCSS(Component, css, "framer-EKSf0") as typeof Component;
export default FramerlUQcbzqmY;

FramerlUQcbzqmY.displayName = "Icon";

FramerlUQcbzqmY.defaultProps = {height: 20, width: 20};

addFonts(FramerlUQcbzqmY, [])